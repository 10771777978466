import request from '@/utils/request'


// 查询充值菜单列表
export function listUser(query) {
  return request({
    url: '/biz/userRechargeMenu/list',
    method: 'get',
    params: query
  })
}

// 查询充值菜单分页
export function pageUser(query) {
  return request({
    url: '/biz/userRechargeMenu/page',
    method: 'get',
    params: query
  })
}

// 查询充值菜单详细
export function getUser(data) {
  return request({
    url: '/biz/userRechargeMenu/detail',
    method: 'get',
    params: data
  })
}

// 新增充值菜单
export function addUser(data) {
  return request({
    url: '/biz/userRechargeMenu/add',
    method: 'post',
    data: data
  })
}

// 修改充值菜单
export function updateUser(data) {
  return request({
    url: '/biz/userRechargeMenu/edit',
    method: 'post',
    data: data
  })
}

// 删除充值菜单
export function delUser(data) {
  return request({
    url: '/biz/userRechargeMenu/delete',
    method: 'post',
    data: data
  })
}
